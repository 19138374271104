import React, { /* useState, */ useRef, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { v4 as uuid } from 'uuid'
import cyrillicToTranslit from 'cyrillic-to-translit-js'

import { fieldNames } from '../constants'
import YKEmailFormView from './YKEmailFormView'
// import { getCity } from 'utils/geo'
// import { fetchCities } from 'store/modules/cities/actions'
import { fetchAllCities } from 'store/modules/allcities/actions'

function YKEmailForm({
  cities,
}) {
  const dispatch = useDispatch()
  const formRef = useRef(null)
  // const [stateCity, setStateCity] = useState({
  //   city: null,
  // })
  const citiesList = [{id: process.env.PROJECT_ID, name: process.env.PROJECT_NAME, has_delivery: false}].concat(cities.data)

  useEffect(() => {
    dispatch(fetchAllCities())
  }, [])

  const formik = useFormik({
    initialValues: {
      [fieldNames.CUSTOMER_NUMBER]: 'UNKNOWN',
      [fieldNames.SUM]: 100,
      [fieldNames.CITY]: null /* stateCity.city */,
      [fieldNames.EMAIL]: '',
      [fieldNames.ADDRESS]: 'UNKNOWN',
      [fieldNames.DETAILS]: '',
    },
    validationSchema: Yup.object().shape({
      [fieldNames.SUM]: Yup
        .number()
        .typeError('Cумма платежа должна быть указана числом')
        .min(1, 'Сумма платежа не может быть меньше 1 рубля')
        .required('Обязательное поле'),
      [fieldNames.CITY]: Yup.string().nullable().required('Укажите город'),
      [fieldNames.EMAIL]: Yup.string().email('Некорректный email'),
    }),
    onSubmit: (values, { setFieldValue }) => {
      const city = values.city || 'UNKNOWN'
          
      setFieldValue(fieldNames.CUSTOMER_NUMBER, `${city}-${uuid()}`)
          
      formRef.current.submit()
      
      // getCity()
      //   .then(response => {
      //     const city = response.location?.data?.city || 'UNKNOWN'
          
      //     setFieldValue(fieldNames.CUSTOMER_NUMBER, `${city}-${uuid()}`)
      //     setFieldValue(fieldNames.ADDRESS, city)
          
      //     // formRef.current.submit()
      //   })
      //   .catch(e => {
      //     setFieldValue(fieldNames.CUSTOMER_NUMBER, `${values[fieldNames.CUSTOMER_NUMBER]}-${uuid()}`)

      //     // formRef.current.submit()
      //     console.log(values)
      //   })
    }
  })

  function handleSelect(selectedCity) {
    formik.setFieldValue(fieldNames.CITY, cyrillicToTranslit().transform(selectedCity.label, '_'))
  }

  return <YKEmailFormView
    values={formik.values}
    errors={formik.errors}
    touched={formik.touched}
    handleChange={formik.handleChange}
    handleSelect={handleSelect}
    handleSubmit={formik.handleSubmit}
    formRef={formRef}
    cities={citiesList}
  />
}

export default connect(
  state => ({
    cities: state.allcities
  })
)(YKEmailForm)