import React from 'react'

import { transformDataForSelect } from 'utils'
import { InputText, Textarea, Select, Button } from 'components/ui'
import { fieldNames } from '../constants'

function YKEmailView({
  values,
  errors,
  touched,
  handleChange,
  handleSelect,
  handleSubmit,
  formRef,
  cities,
}) {
  return (
    <div className='form-layout'>
      <form
        action="https://yoomoney.ru/eshop.xml"
        method="post"
        target="_blank"
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <input required name="shopId" value={ process.env.YMSHOPID } type="hidden"/>
        <input required name="scid" value={ process.env.YMSCID } type="hidden"/>
        <input required name="customerNumber" value={values[fieldNames.CUSTOMER_NUMBER]} size="64" type="hidden" readOnly/>
        <input name="custAddr" value={values[fieldNames.ADDRESS]} size="64" type="hidden" readOnly/>
        <div className='form-field'>
          <InputText
            required
            type="text"
            placeholder="Сумма пожертвования в рублях"
            name={fieldNames.SUM}
            value={values[fieldNames.SUM]}
            error={touched[fieldNames.SUM] && errors[fieldNames.SUM]}
            onChange={handleChange}
          />
        </div>
        <div className='form-field'>
          <Select
            placeholder="Выберите город"
            options={transformDataForSelect(cities)}
            label="Город, куда направить пожертвование"
            error={touched[fieldNames.CITY] && errors[fieldNames.CITY]}
            onChange={handleSelect}
          />
        </div>
        <div className='form-field'>
          <InputText
            type="email"
            placeholder="E-mail (необязательно)"
            name={fieldNames.EMAIL}
            value={values[fieldNames.EMAIL]}
            error={touched[fieldNames.EMAIL] && errors[fieldNames.EMAIL]}
            onChange={handleChange}
          />
        </div>
        <div className='form-field'>
          <Textarea
            label="Комментарий (необязательно)"
            placeholder="Ваши пожелания"
            id={values[fieldNames.DETAILS]}
            name={fieldNames.DETAILS}
            value={values[fieldNames.DETAILS]}
            error={touched[fieldNames.DETAILS] && errors[fieldNames.DETAILS]}
            onChange={handleChange}
          />
        </div>
        <br />
        <div className='form-field'>
          <Button
            blue
            type="submit"
            text="Пожертвовать"
          />
        </div>
      </form>
    </div>
  )
}

export default YKEmailView